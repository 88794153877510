import React, { useContext, useState, useEffect } from 'react';
import { GrSearch, GrMenu, GrClose } from "react-icons/gr";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Icons for dropdown open/close
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { setUserDetails } from '../store/userSlice';
import Context from '../context';
import { FaPhoneAlt } from "react-icons/fa";
const Header = () => {
  const user = useSelector(state => state?.user?.user);
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // For mobile menu
  const [isDropdownOpen, setDropdownOpen] = useState(false); // For "Other Links" dropdown
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll("q");
  const [search, setSearch] = useState(searchQuery);
  const [headerTitle, setHeaderTitle] = useState(''); // For storing header title
  const [logoUrl, setLogoUrl] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [thirdColor, setThirdColor] = useState(''); // Set default colors
  const [permissions, setPermissions] = useState(null);


  const [aboutData, setAboutData] = useState(null);


  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        // console.log("data",data);
        setAboutData(data);
      } catch (error) {
        // console.error("Error fetching About Us data:", error);
      }
    };

    
     
     

    fetchAboutData();
 
  }, [`${process.env.REACT_APP_USER_ID}`]);
 console.log(permissions)
 const hasAnyTrue = ['showBlogs', 'showClientPage', 'showTestimonialsPage']
  .some(key => permissions?.[key] === true);

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-footer-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
          setThirdColor(data.data.thirdColor);
        }
      } catch (error) {
        console.error('Error fetching header colors:', error);
      }
    };

    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logoResponse = await fetch(`https://builder.biztoindia.in/api/load-logo/${process.env.REACT_APP_USER_ID}`);
        const logos = await logoResponse.json();
        setLogoUrl(logos?.path);

        const headerResponse = await fetch(`https://builder.biztoindia.in/api/get-header/${process.env.REACT_APP_USER_ID}`);
        const headerData = await headerResponse.json();
        setHeaderTitle(headerData?.headerTitle || 'Products');
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    });

    const data = await fetchData.json();

    if (data.success) {
      toast.success(data.message);
      dispatch(setUserDetails(null));
      navigate("/");
    }

    if (data.error) {
      toast.error(data.message);
    }
  };


  const fetchPermissions = async () => {
    try {
      const response = await fetch(
        `https://builder.biztoindia.in/api/permission-get/${process.env.REACT_APP_USER_ID}`
      );
      const data = await response.json();
      //  console.log("permission",data);
      
      setPermissions(data.permissions);
    } catch (error) {
      // console.error('Error fetching permissions:', error);
    }
  };
useEffect(()=>{
 fetchPermissions();
},[ `${process.env.REACT_APP_USER_ID}`])
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value) {
      navigate(`/search?q=${value}`);
    } else {
      navigate("/search");
    }
  };

  // Close the mobile menu on link click
  const handleLinkClick = () => {
    setMobileMenuOpen(false);
    setDropdownOpen(false); // Close dropdown on link click
  };

  // Toggle the dropdown on button click
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className='h-16 font-poppins shadow-md fixed w-full z-40' style={{ backgroundColor: backgroundColor }}>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        
        {/* Logo */}
        <div className="flex items-center justify-center w-24 h-12 overflow-hidden">
          {logoUrl ? (
            <img
              src={`https://builder.biztoindia.in/${logoUrl}`}
              alt="Logo"
              className="w-full h-full object-contain"
              onClick={() => navigate("/")}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            ""
          )}
         
        </div>
     { aboutData?.contactNumber &&  <div className='md:hidden text-sm flex items-center rounded-full p-2 border border-gray-300 shadow-lg' style={{ backgroundColor: secondaryColor }}>
  <span className='font-semibold mr-2 text-xl' style={{ color: backgroundColor }}>
    <FaPhoneAlt />
  </span> {/* Phone icon */}
  <a href={`tel:${aboutData?.contactNumber}`} className='font-medium' style={{ color: thirdColor }}>
    {aboutData?.contactNumber}
  </a> {/* Contact number */}
</div> }



        {/* Mobile Menu Toggle Button */}
        <div className='lg:hidden'>
          {mobileMenuOpen ? (
            <GrClose className='text-3xl cursor-pointer' onClick={() => setMobileMenuOpen(false)} style={{ color: primaryColor }} />
          ) : (
            <GrMenu className='text-3xl cursor-pointer' onClick={() => setMobileMenuOpen(true)} style={{ color: primaryColor }} />
          )}
        </div>

        {/* Desktop Search Input */}
        <div className='hidden lg:flex font-poppins items-center flex-1 justify-between max-w-md border rounded-full focus-within:shadow-md'>
          <input
            type='text'
            placeholder={`Search ${headerTitle}...`}
            className='w-full p-2 rounded-l-full outline-none'
            style={{  color:  backgroundColor }}
            onChange={handleSearch}
            value={search}
           
          />
          <button className='p-2 rounded-r-full' style={{ backgroundColor: thirdColor, color: secondaryColor }}>
  <GrSearch className='text-lg' />
</button>

        </div>

        {/* Desktop Links */}
        <nav className='hidden lg:flex items-center space-x-4'>
          <Link to="/" className='  cursor-pointer hover:text-red-600 transition' style={{ color: primaryColor }}>Home</Link>
          <Link to="/about" className='  cursor-pointer hover:text-red-600 transition' style={{ color: primaryColor }}>About Us</Link>
          <Link to="/all-products" className=' cursor-pointer  hover:text-red-600 transition' style={{ color: primaryColor }}>{headerTitle || 'Products'}</Link>
         
         { 
         permissions?.showGalleryPage &&
          <Link to="/Gallery" className=' cursor-pointer  hover:text-red-600 transition' style={{ color: primaryColor }}>Gallery</Link>
         } 

      
          <Link to="/contact" className=' cursor-pointer  hover:text-red-600 transition' style={{ color: primaryColor }}>Contact Us</Link>
 
  
          <div className="relative">
 
   {


hasAnyTrue &&  <button
className="flex items-center transition"
style={{ color: primaryColor }}
onClick={handleDropdownToggle}
>
More... {isDropdownOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
</button>
   }
  
     

  {isDropdownOpen && (
    <ul className="absolute mt-5 w-64  border rounded-lg shadow-lg text-sm z-50" style={{backgroundColor:backgroundColor,left:'-100px',color:secondaryColor}}  >
         { 
         permissions?.showBlogs &&
        <li>
  <Link to="blogs"  className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>Blogs</Link>
  <hr className="border-t border-gray-200" />
        </li>

         }
        
       {  permissions?.showClientPage &&
 <li>
 <Link to="allclients" className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>              Our Clients
 </Link>
 <hr className="border-t border-gray-200" />
</li>

       }
        { permissions?.showTestimonialsPage &&
<li>
          <Link to="testimonials" className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>
           Testimonials
          </Link>
          <hr className="border-t border-gray-200" />
        </li>
        }
        

      
    </ul>
  )}
</div>

        </nav>

        {/* Mobile Menu */} 
{mobileMenuOpen && (
  <nav className='absolute top-16 font-poppins left-0 w-full shadow-lg lg:hidden' style={{ backgroundColor: backgroundColor }}>
    <ul className='flex flex-col items-center space-y-4 py-4'>
      <li><Link to="/" className=' transition cursor-pointer' style={{ color: primaryColor }} onClick={handleLinkClick}>Home</Link></li>
      <li><Link to="/about" className='cursor-pointer  transition' style={{ color: primaryColor }} onClick={handleLinkClick}>About Us</Link></li>
      <li><Link to="/all-products" className=' cursor-pointer transition' style={{ color: primaryColor }} onClick={handleLinkClick}>{headerTitle || 'Products'}</Link></li>
   {
permissions?.showGalleryPage &&
<li><Link to="/Gallery" className=' cursor-pointer transition' style={{ color: primaryColor }} onClick={handleLinkClick}>Gallery</Link></li>
   }

   
   
      <li><Link to="/contact" className='cursor-pointer transition' style={{ color: primaryColor }} onClick={handleLinkClick}>Contact Us</Link></li>

      {/* Other Links Dropdown for Mobile */}
      <div className="relative w-full flex flex-col items-center font-poppins">
       
       
       {

        hasAnyTrue && <button className="  flex items-center transition font-poppins" style={{ color: primaryColor }} onClick={handleDropdownToggle}>
        More... {isDropdownOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
      </button>
       } 
        {isDropdownOpen && (
          <ul className="w-11/12 mx-auto border rounded-lg font-poppins shadow-lg text-sm max-h-60 overflow-y-auto" style={{ backgroundColor: backgroundColor,color:secondaryColor }}>
             {  permissions?.showClientPage &&
 <li>
 <Link to="allclients" className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>              Our Clients
 </Link>
 <hr className="border-t border-gray-200" />
</li>

       }
        { permissions?.showTestimonialsPage &&
<li>
          <Link to="testimonials" className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>
           Testimonials
          </Link>
          <hr className="border-t border-gray-200" />
        </li>
        }
        { permissions?.showBlogs &&
        <li>
  <Link to="blogs"  className="block px-4 py-3   whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer" onClick={handleLinkClick}>Blogs</Link>
  <hr className="border-t border-gray-200" />
        </li>

         }

       
          </ul>
        )}
      </div>
    </ul>
  </nav>
)}


      </div>
    </header>
  );
};

export default Header;   