import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const AboutUs = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state?.user?.user);
  const [showFullMission, setShowFullMission] = useState(false);
  const [showFullWhyChooseUs, setShowFullWhyChooseUs] = useState(false);
  const [showFullAboutUs, setShowFullAboutUs] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const fallbackData = {
    companyName: '',
    aboutUs: '',
    ourMission: '',
    whyChooseUs: ''
  };

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    };
    fetchColors();
  }, []);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
        if (response.ok) {
          const data = await response.json();
          setAboutData(data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchAboutData();
  }, [user?._id]);

  if (loading) {
    return <p className="text-center text-lg font-semibold">Loading...</p>;
  }
  const truncateText = (text, maxLength = 143) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

   if(!aboutData ){
    return null;
   }

  return (
    <article
      style={{
        background: `${backgroundColor}`,
        padding: '4rem 1rem',
      }}
    >
       
       <Helmet>
  <title>{`About-Us | ${aboutData?.companyName || 'Our Website'}`}</title>
  <meta
    name="description"
    content={truncateText(aboutData?.aboutUs || fallbackData.aboutUs)}
  />
  <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
  <link rel="canonical" href={window.location.href} />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta charSet="utf-8" />

  {/* Open Graph Meta Tags */}
  <meta property="og:title" content={`Home | ${aboutData?.companyName}`} />
  <meta
    property="og:description"
    content={truncateText(aboutData?.aboutUs || fallbackData.aboutUs)}
  />
   {/* Update with correct path */}
  <meta property="og:url" content={window.location.href} />
  <meta property="og:type" content="website" />

  {/* Twitter Card Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={`Home | ${aboutData?.companyName}`} />
  <meta
    name="twitter:description"
    content={truncateText(aboutData?.aboutUs || fallbackData.aboutUs)}
  />
  {/* <meta name="twitter:image" content="/path-to-your-image.jpg" /> Update with correct path */}
   {/* Update with your Twitter handle */}
</Helmet>

      <header className="max-w-7xl mx-auto text-center font-montserrat">
        <h1
          className="mt-2 text-4xl leading-10 font-extrabold sm:text-5xl"
          style={{ color: primaryColor }}
        >
          {aboutData?.companyName || fallbackData.companyName}
        </h1>

        <p
          className="mt-6 w-full text-lg mx-auto"
          style={{ color: secondaryColor }}
        >
          {showFullAboutUs
            ? aboutData?.aboutUs || fallbackData.aboutUs
            : `${(aboutData?.aboutUs || fallbackData.aboutUs).substring(0, 200)}...`}
        </p>
        <button
          onClick={() => setShowFullAboutUs(!showFullAboutUs)}
          className="flex items-center justify-center font-semibold hover:text-blue-800 transition-all duration-300 mt-4"
          style={{ color: primaryColor }}
        >
          {showFullAboutUs ? (
            <>
              <FaChevronUp className="mr-2" /> Show Less
            </>
          ) : (
            <>
              <FaChevronDown className="mr-2" /> Read More
            </>
          )}
        </button>
      </header>

      <section className="mt-12 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 font-montserrat">
        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h2 className="text-3xl font-semibold" style={{ color: primaryColor }}>
            Our Mission
          </h2>
          <p style={{ color: secondaryColor }}>
            {showFullMission
              ? aboutData?.ourMission || fallbackData.ourMission
              : `${(aboutData?.ourMission || fallbackData.ourMission).substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullMission(!showFullMission)}
            className="flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
            style={{ color: primaryColor }}
          >
            {showFullMission ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>

        <div className="space-y-6 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
          <h2 className="text-3xl font-semibold" style={{ color: primaryColor }}>
            Why Choose Us?
          </h2>
          <p style={{ color: secondaryColor }}>
            {showFullWhyChooseUs
              ? aboutData?.whyChooseUs || fallbackData.whyChooseUs
              : `${(aboutData?.whyChooseUs || fallbackData.whyChooseUs).substring(0, 200)}...`}
          </p>
          <button
            onClick={() => setShowFullWhyChooseUs(!showFullWhyChooseUs)}
            className="flex items-center font-semibold hover:text-blue-800 transition-all duration-300"
            style={{ color: primaryColor }}
          >
            {showFullWhyChooseUs ? (
              <>
                <FaChevronUp className="mr-2" /> Show Less
              </>
            ) : (
              <>
                <FaChevronDown className="mr-2" /> Read More
              </>
            )}
          </button>
        </div>
      </section>
    </article>
  );
};

export default AboutUs;
