import { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const OurClients = ({ companyName }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();

        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        // Handle error appropriately
      }
    };
    fetchHeaderColor();
  }, [`${process.env.REACT_APP_USER_ID}`]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/clients?owner=${process.env.REACT_APP_USER_ID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      
        if (!response.ok) {
          throw new Error('Error fetching images');
        }

        const data = await response.json();
        // console.log("uploadedImage",data);
        setUploadedImages(data);
      } catch (error) {
        // console.error('Error fetching images:', error);
        // toast.error('Error fetching images.');
      }
    };

    fetchImages();
  }, []);

  // Calculate the total number of images
  const totalImages = uploadedImages.reduce((total, client) => total + client.imageUrls.length, 0);
//  console.log("tttt",totalImages);
  return (
    <>
       
    { totalImages>0 &&
  <div className="w-full mx-auto p-8 rounded-lg">
  <Toaster />
  <h2 className="text-4xl font-extrabold text-center mb-10" style={{ color: primaryColor }}>
    Our Clients
  </h2>
  <p className="text-center text-lg mb-8" style={{ color: secondaryColor }}>
    We are proud to work with a wide range of clients from various industries. Some of them are shown below.
  </p>

  {/* Only display if there are uploaded images */}
  {totalImages > 0 ? (
    <div className="flex flex-wrap justify-center gap-4">
      {uploadedImages
        .flatMap(client => client.imageUrls)
        .slice(0, 7)
        .map((url, index) => (
          <div
            key={index}
            className="flex items-center justify-center p-4 rounded-md shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300 w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
            style={{
              minWidth: '150px',
              height: '150px',
            }}
          >
            <img
              src={`https://builder.biztoindia.in/${url}`}
              alt={`Client ${index}`}
              className="object-contain w-full h-full rounded-md"
            />
          </div>
        ))}

      {/* "View More" button as the 10th item */}
    { 
    totalImages>6 &&
      <div
        key="view-more"
        className="flex items-center justify-center p-4 rounded-md shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300 w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
        style={{
          height: '150px',
        }}
      >
        <Link to="/allclients">
          <button
            style={{ backgroundColor: primaryColor, color: secondaryColor }}
            className="px-6 py-2 rounded-lg hover:bg-blue-700 transition duration-200"
          >
            View More
          </button>
        </Link>
      </div>
}
    </div>
     
  ) : (
    <p className="text-center text-lg" style={{ color: secondaryColor }}>
      No client logos available.
    </p>
  )}
</div>


    }  
    
    </>
  );
};

export default OurClients;
