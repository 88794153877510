import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import FloatingIcons from "./FloatingIcons";
import SummaryApi from "../common";
// import { useSelector } from "react-redux";

const Footer = () => {
  const [aboutData, setAboutData] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [allProduct, setAllProduct] = useState([]);
  const [headerColor, setHeaderColor] = useState({
    primaryColor: "",
    secondaryColor: "",
    backgroundColor: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        setAboutData(data);
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      }
    };

    const fetchPermissions = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/permission-get/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        setPermissions(data.permissions);
      } catch (error) {
        // console.error("Error fetching permissions:", error);
      }
    };

    const fetchAllProduct = async () => {
      try {
        const response = await fetch(
          `${SummaryApi.allProduct.url}/${process.env.REACT_APP_USER_ID}`
        );
        const dataResponse = await response.json();
        setAllProduct(dataResponse?.data || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-header-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        if (data.success) {
          setHeaderColor(data.data);
        }
      } catch (error) {
        console.error("Error fetching header colors:", error);
      }
    };

    fetchAboutData();
    fetchPermissions();
    fetchAllProduct();
    fetchHeaderColor();
  }, [process.env.REACT_APP_USER_ID]);

  const handleReadMore = () => navigate("/about");

  const getShortenedText = (text, length = 100) =>
    text?.length > length ? text.substring(0, length) + "..." : text;

  const socialMediaLinks = [
    { Icon: FaFacebookF, id: aboutData?.facebookId|| "", url: `https://facebook.com/${aboutData?.facebookId}` },
    { Icon: FaInstagram, id: aboutData?.instagramId ||"", url: `https://instagram.com/${aboutData?.instagramId}` },
    { Icon: FaLinkedinIn, id: aboutData?.linkedinId || "", url: `https://linkedin.com/in/${aboutData?.linkedinId}` },
    { Icon: FaTwitter, id: aboutData?.twitterId || "", url: `https://twitter.com/${aboutData?.twitterId}` },
    { Icon: FaYoutube, id: aboutData?.youtubeId || "", url: `https://youtube.com/channel/${aboutData?.youtubeId}` },
  ];
  // console.log("permissions",permissions)

  return (
    <>
      <footer className="py-10 font-poppins" style={{ backgroundColor: headerColor.backgroundColor }}>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-1 text-sm" style={{ color: headerColor.primaryColor }}>
            {/* Company Info */}
            <div>
              <h2 className="text-lg font-semibold mb-4">{aboutData?.companyName || "Company Name"}</h2>
              <p>{getShortenedText(aboutData?.aboutUs, 150)}</p>
              <button
                onClick={handleReadMore}
                className="mt-4 flex items-center px-4 py-2 bg-white text-black rounded hover:bg-gray-200 transition-colors"
              >
                Read More <FiArrowRight className="ml-2" />
              </button>
            </div>

            {/* Products */}
            <div className="md:ml-20">
              <h2 className="text-lg font-semibold mb-4">Products</h2>
              <ul>
                {allProduct.slice(0, 4).map((pname, index) => (
                  <Link to={`/product/${pname?.slug}`} key={index}>
                    <li>{pname?.productName.substring(0, 20) + (pname.productName.length > 20 ? "..." : "")}</li>
                  </Link>
                ))}
                {allProduct.length > 4 && (
                  <li>
                    <Link to="/all-products" className="text-blue-600 font-semibold hover:underline">
                      View More
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            {/* Quick Links */}
          
            <div className="md:ml-10">
  <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
  <ul>
    <li className="mb-2">
      <a href="/" className="hover:underline">
        Home
      </a>
    </li>
    <li className="mb-2">
      <a href="/all-products" className="hover:underline">
        Products
      </a>
    </li>
    <li className="mb-2">
      <a href="/about" className="hover:underline">
        About Us
      </a>
    </li>
    <li className="mb-2">
      <a href="/contact" className="hover:underline">
        Contact Us
      </a>
    </li>
  </ul>
</div>
 


            {/* Other Links */}
            <div>
              <h2 className="text-lg font-semibold mb-4">Other Links</h2>
              <ul>
                {permissions?.showFaq && <li className="mb-2"><a href="/faq" className="hover:underline">FAQ</a></li>}
                {permissions?.showPrivacy && <li className="mb-2"><a href="/privacy-policy" className="hover:underline">Privacy Policy</a></li>}
                {permissions?.showRefundPage && <li className="mb-2"><a href="/refundpolicy" className="hover:underline">Refund Policy</a></li>}
                {permissions?.showShippingPage && <li className="mb-2"><a href="/shippingandretunpolicy" className="hover:underline">Shipping Policy</a></li>}
               
                {permissions?.showTermofUsePage && <li className="mb-2"><a href="/termsofuse" className="hover:underline">Terms of Use</a></li>}
                 
                <li className="mb-2"><a href="/sitemap" className="hover:underline">Sitemap</a></li>

                
              </ul>
            </div>

            {/* Stay Connected */}
            <div>
              <h2 className="text-lg font-semibold mb-4">Stay Connected</h2>
              <form className="flex flex-col sm:flex-row items-start">
                <input type="email" placeholder="Enter your email" className="p-2 mb-2 sm:mr-2 rounded bg-white text-black focus:outline-none" />
                <button type="submit" className="p-2 bg-white text-black font-semibold rounded hover:bg-gray-200">
                  Subscribe
                </button>
              </form>
             
               
              <div className="mt-4 flex">
    {socialMediaLinks.map(({ Icon, id, url }, index) =>
      id!=="" ? (
        <a
          key={index} // Add a unique key for each mapped element
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 hover:underline flex items-center"
        >
          <Icon className="mr-2 text-xl" />
        </a>
      ) : null
    )}
  </div>
             
            </div>
          </div>

          <div className="border-t border-gray-700 text-center mt-4 text-sm -mb-10" style={{ color: headerColor.secondaryColor}}>
            <p>
              &copy; {new Date().getFullYear()} {aboutData?.companyName || "Your Company"}. All Rights Reserved. Powered by{" "}
              <a href="https://biztoindia.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Bizto India
              </a>
            </p>
          </div>
        </div>
      </footer>

      <FloatingIcons />
    </>
  );
};

export default Footer;
