import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Herosection = () => {
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [aboutData, setAboutData] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", email: "", mobile: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const user = useSelector(state => state?.user?.user);

    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
                if (response.ok) {
                    const data = await response.json();
                    setAboutData(data);
                } else {
                    // throw new Error('Failed to fetch data');
                }
            } catch (err) {
                // console.error('Error fetching about data:', err.message);
            }
        };

        fetchAboutData();
    }, [user?._id]);

    useEffect(() => {
        const fetchHeaderColor = async () => {
            try {
                const response = await fetch(`https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`);
                const data = await response.json();
                if (data.success) {
                    setPrimaryColor(data.data.primaryColor);
                    setSecondaryColor(data.data.secondaryColor);
                    setBackgroundColor(data.data.backgroundColor);
                }
            } catch (error) {
                // console.error('Error fetching colors:', error.message);
            }
        };
        fetchHeaderColor();
    }, [process.env.REACT_APP_USER_ID]);

    const handlePopupOpen = () => setIsPopupOpen(true);
    const handlePopupClose = () => setIsPopupOpen(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const emailData = {
            to: formData.email,
            subject: `Enquiry from ${formData.name}`,
            text: `Name: ${formData.name}\nMobile: ${formData.mobile}\nEmail: ${formData.email}`,
            html: `<p><strong>Name:</strong> ${formData.name}</p><p><strong>Mobile:</strong> ${formData.mobile}</p><p><strong>Email:</strong> ${formData.email}</p>`,
            product: aboutData,
        };

        try {
            const response = await fetch(`https://builder.biztoindia.in/api/send-email/${process.env.REACT_APP_USER_ID}`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(emailData),
            });

            const responseData = await response.json();
            toast.success("Email sent successfully!");
            setFormData({ name: "", email: "", mobile: "" });
            setIsPopupOpen(false);
        } catch (error) {
            toast.error(error.message || "An error occurred while sending the email.");
        } finally {
            setIsSubmitting(false);
        }
    };

    // Return null if aboutData is not available
    if (!aboutData) return null;

    return (
        <div className="flex flex-col items-center text-center font-montserrat px-4 py-4 md:px-6 lg:px-8 lg:py-10" style={{ backgroundColor }}>
            <h1 className="text-2xl md:text-5xl lg:text-5xl font-bold mb-2" style={{ color: primaryColor }}>
                {aboutData.companyName || "Empowering Your Online Shopping Experience"}
            </h1>
            <p
                className="line-clamp-2 mb-4 md:text-base lg:text-lg w-full md:w-full lg:w-2/2 mx-auto"
                style={{ color: secondaryColor }}
            >
                {aboutData.aboutUs || "Discover amazing products and enjoy unbeatable value."}
            </p>

            <div className="flex flex-row md:flex-row justify-center gap-4 w-full">
                <Link
                    to="about"
                    className="flex-2 px-6 py-3 rounded text-sm md:text-base text-center"
                    style={{ color:   backgroundColor, backgroundColor: primaryColor }}
                >
                    Read More
                </Link>
                <button
                    className="flex-2 px-6 py-3 rounded text-sm md:text-base text-center"
                    style={{ color:  backgroundColor, backgroundColor: primaryColor }}
                    onClick={handlePopupOpen}
                >
                    Enquire Now
                </button>
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4 sm:px-6">
                    <div className="bg-white rounded-xl shadow-lg p-6 sm:p-8 max-w-lg w-full relative sm:mx-auto sm:my-6">
                        <button
                            onClick={handlePopupClose}
                            className="absolute top-2 right-2 text-2xl font-bold text-gray-600 hover:text-gray-800 transition-colors"
                        >
                            &times;
                        </button>
                        <h2 className="text-xl font-semibold mb-6 text-center text-gray-800">
                            Enquiry Form
                        </h2>
                        <form onSubmit={handleSubmit} className="space-y-3">
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full px-4 py-3 border rounded-lg shadow-sm"
                                placeholder="Enter your name"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-4 py-3 border rounded-lg shadow-sm"
                                placeholder="Enter your email"
                                required
                            />
                            <input
                                type="tel"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="w-full px-4 py-3 border rounded-lg shadow-sm"
                                placeholder="Enter your mobile number"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-full shadow-lg hover:scale-105"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Submit Enquiry"}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Herosection;
