import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { FaChevronRight, FaShareAlt, FaCalendarAlt } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai'; // Import rating star icon
// import { useSelector } from 'react-redux';

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [commentFormVisible, setCommentFormVisible] = useState(false); // State for showing/hiding comment form
  const [comments, setComments] = useState([]);
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  // const user = useSelector((state) => state?.user?.user);
  useEffect(() => {
    const fetchHeaderColor = async () => {
      try {
        const response = await fetch(
          `https://builder.biztoindia.in/api/get-body-color/${process.env.REACT_APP_USER_ID}`
        );
        const data = await response.json();
        if (data.success) {
          setPrimaryColor(data.data.primaryColor);
          setSecondaryColor(data.data.secondaryColor);
          setBackgroundColor(data.data.backgroundColor);
        }
      } catch (error) {
        toast.error('Error fetching colors');
      }
    };
    fetchHeaderColor();
  }, []);

  useEffect(() => {

    const fetchComments = async () => {
      try {
        // console.log("Fetching comments for blog ID:", id);
        const response = await fetch(`https://builder.biztoindia.in/api/blogs/${id}/comments`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const data = await response.json(); // Parse response
        // console.log("Comments data:", data);
        setComments(data); 
      } catch (error) {
        // console.error(error);  
        // toast.error('Error fetching comments');
      }
    };
    






    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/blogs/${id}`);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        toast.error('Error fetching blog');
      }
    };
    fetchBlog();
    fetchComments();
  }, [id]);

  useEffect(() => {
    
    const fetchRecentBlogs = async () => {
      try {
        const response = await fetch(`https://builder.biztoindia.in/api/blogss/${process.env.REACT_APP_USER_ID}`);
        const data = await response.json();
        const filteredBlogs = data.filter((recentBlog) => recentBlog._id !== id);
        setRecentBlogs(filteredBlogs.slice(0, 10)); // Limit to 10 recent blogs
      } catch (error) {
        // toast.error('Error fetching recent blogs');
      }
    };
    fetchRecentBlogs();
  }, [id]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: blog.title,
          content:blog.content,
          url: window.location.href,
        });
        toast.success('Blog shared successfully!');
      } catch (error) {
        toast.error('Error sharing blog');
      }
    } else {
      toast.info('Sharing not supported in this browser');
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://builder.biztoindia.in/api/blogs/${id}/comments`, { // Updated endpoint
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          content: feedback, // renamed to `content` to match backend
          rating,
        }),
      });
      const result = await response.json();
      // console.log("result", result);
      if (result.success) {
        toast.success('Comment submitted successfully!');
        setName('');
        setFeedback('');
        setRating(0);
        setCommentFormVisible(false);
      } else {
        toast.error('Failed to submit comment');
      }
    } catch (error) {
      toast.error('Error submitting comment');
    }
  };
  
  if (!blog) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <>
        <Helmet>
        <title>{blog.metaTitle || blog.title}</title>
        <meta name="description" content={blog.metaDescription || blog.content.slice(0, 150)} />
        <meta property="og:title" content={blog.metaTitle || blog.title} />
        <meta property="og:description" content={blog.metaDescription || blog.content.slice(0, 150)} />
        <meta property="og:image" content={`https://builder.biztoindia.in/${blog.thumbnail.path}`} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.metaTitle || blog.title} />
        <meta name="twitter:description" content={blog.metaDescription || blog.content.slice(0, 150)} />
        <meta name="twitter:image" content={`https://builder.biztoindia.in/${blog.thumbnail.path}`} />
        <meta name="keywords" content={blog.metaKeywords} />
        <link rel="canonical" href={`{https://builder.biztoindia.in/blog/${blog.slug}`} />
      </Helmet>


      {/* Blog Hero Section */}
      <div
        className="flex justify-center items-center p-4 md:p-10 bg-cover bg-center"
        style={{
          backgroundImage: `url(https://builder.biztoindia.in/${blog.thumbnail.path})`,
        }}
      >
        <p
          className="text-center text-2xl md:text-3xl lg:text-4xl font-bold p-3 bg-opacity-80 rounded-md"
          style={{ color: primaryColor, backgroundColor }}
        >
          {blog.title}
        </p>
      </div>

      <div className="container mx-auto p-4 grid grid-cols-1 lg:grid-cols-4 gap-8" style={{ backgroundColor }}>
        {/* Blog Details */}
        <div className="lg:col-span-3 overflow-y-auto h-[80vh] scrollbar-thin scrollbar-thumb-gray-400">
          <div className="p-4">
            {/* Share Button */}
            <div className="flex justify-between items-center mb-4">
              <div>
                <h1 className="text-xl font-bold mb-2 p-1 rounded-md" style={{ color: backgroundColor, backgroundColor: primaryColor }}>
                  {blog.title}
                </h1>
                <p className="flex items-center text-sm" style={{ color: secondaryColor }}>
                  <FaCalendarAlt className="mr-2" /> Published on {new Date(blog.createdAt).toLocaleDateString()}
                </p>
              </div>

              <button
                onClick={handleShare}
                className="flex items-center p-2 rounded-md transition"
                style={{ color: primaryColor, backgroundColor: secondaryColor }}
              >
                <FaShareAlt className="mr-2" />
                Share
              </button>
            </div>

            {/* Blog Content */}
            <div className="flex flex-col lg:flex-row items-start">
              <img
                src={`https://builder.biztoindia.in/${blog.thumbnail.path}`}
                alt={blog.title}
                className="w-full lg:w-1/3 h-auto object-contain rounded-md lg:mr-4"
              />
              <div className="flex-1 mt-4 lg:mt-0">
                <p className="text-lg font-semibold p-2" style={{ color: primaryColor }}>
                  {blog.category}
                </p>
                <div
                  className="ql-editor prose max-w-none leading-relaxed"
                  style={{ color: secondaryColor }}
                  dangerouslySetInnerHTML={{ __html: blog.content.slice(0, 400) }}
                />
              </div>
            </div>

            <div className="mt-4">
              <div
                className="ql-editor prose max-w-none leading-relaxed"
                style={{ color: secondaryColor }}
                dangerouslySetInnerHTML={{ __html: blog.content.slice(400) }}
              />
            </div>

            {/* Button to open comment form */}
            <button
              onClick={() => setCommentFormVisible(!commentFormVisible)}
              className="mt-1 p-2 bg-blue-500 text-white rounded-md"
            >
              {commentFormVisible ? 'Cancel Comment' : 'Add Comment'}
            </button>
            <div className="mt-8">
  <h3 className="text-lg font-semibold mb-4" style={{ color: primaryColor }}>Comments</h3>
  <ul className="space-y-4">
    {comments?.length ? (
      comments?.map((comment) => (
        <li key={comment._id} className="p-4 border rounded-md bg-gray-50">
          <div className="flex items-center justify-between mb-2">
            <span className="font-semibold">{comment?.user}</span>
            <div className="flex items-center">
              {[...Array(comment?.rating)].map((_, i) => (
                <AiFillStar key={i} className="text-yellow-500" />
              ))}
              {[...Array(5 - comment.rating)].map((_, i) => (
                <AiFillStar key={i} className="text-gray-300" />
              ))}
            </div>
          </div>
          <p className="text-sm mb-2 text-yellow-500">
            {comment.content}
          </p>
          <span className="text-xs text-gray-500">{new Date(comment.createdAt).toLocaleDateString()}</span>
        </li>
      ))
    ) : (
      <p className="text-sm text-gray-500">No comments yet.</p>
    )}
  </ul>
</div>

            {/* Comment Form */}
            {commentFormVisible && (
              <form onSubmit={handleCommentSubmit} className="mt-4">
                <div>
                  <label className="block text-sm font-medium" style={{ color: primaryColor }}>
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 p-2 border rounded-md w-full"
                    required
                  />
                </div>
                <div className="mt-2">
                  <label className="block text-sm font-medium" style={{ color: primaryColor }}>
                    Feedback
                  </label>
                  <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="mt-1 p-2 border rounded-md w-full"
                    required
                  />
                </div>
                <div className="mt-2">
                  <label className="block text-sm font-medium" style={{ color: primaryColor }}>
                    Rating
                  </label>
                  <div className="flex">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <AiFillStar
                        key={star}
                        className={`cursor-pointer ${rating >= star ? 'text-yellow-500' : 'text-gray-300'}`}
                        onClick={() => setRating(star)}
                        size={24}
                      />
                    ))}
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-4 p-2   text-white rounded-md" 
                  style={{backgroundColor:primaryColor, color:backgroundColor}}
                >
                  Submit Comment
                </button>
              </form>
            )}
          </div>
        </div>

        {/* Recent Blogs */}
        <div className="shadow-lg rounded-md overflow-hidden relative" style={{ backgroundColor }}>
          <h2
            className="text-center p-1 text-xl md:text-2xl font-bold tracking-wide bg-opacity-80 mb-4"
            style={{ color: backgroundColor, backgroundColor: primaryColor }}
          >
            Recent Blogs
          </h2>
          <ul className="space-y-4 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400">
            {recentBlogs?.map((recentBlog) => (
              <li
                key={recentBlog._id}
                className="flex items-center justify-between cursor-pointer p-2 rounded-md hover:bg-gray-100 transition"
                onClick={() => navigate(`/blogs/${recentBlog?.slug}`)}
              >
                <img
                  src={`https://builder.biztoindia.in/${recentBlog?.thumbnail.path}`}
                  alt={recentBlog.title}
                  className="w-16 h-16 object-cover rounded-md mr-4"
                />
                <span className="text-lg flex-1" style={{ color: secondaryColor }}>
                  {recentBlog.title}
                  <p className="flex items-center text-sm" style={{ color: secondaryColor }}>
                    <FaCalendarAlt className="mr-2" /> Published on {new Date(blog.createdAt).toLocaleDateString()}
                  </p>
                </span>
                <FaChevronRight className="text-gray-500" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
