import React, { useState, useEffect } from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { useSelector } from 'react-redux';
 

const BannerProduct = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [desktopImages, setDesktopImages] = useState([]);
    const [mobileImages, setMobileImages] = useState([]);
    const [aboutData, setAboutData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(state => state?.user?.user);
    
   
    useEffect(() => {
        const fetchBannerImages = async () => {
            try {
                const response = await fetch(`https://builder.biztoindia.in/api/get-banner/${process.env.REACT_APP_USER_ID}`);
                const data = await response.json();
                if (Array.isArray(data?.data) && data.data.length > 0) {
                    setDesktopImages(data.data[0].desktopImages);
                    setMobileImages(data.data[0].mobileImages);
                }
            } catch (error) {
                console.error('Error fetching banner images:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchAboutData = async () => {
            try {
                const response = await fetch(`https://builder.biztoindia.in/api/aboutget/${process.env.REACT_APP_USER_ID}`);
                if (response.ok) {
                    const data = await response.json();
                    setAboutData(data);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (err) {
                // console.error('Error fetching about data:', err.message);
            }
        };

        fetchBannerImages();
        fetchAboutData();
    }, [user?._id]);

    const nextImage = () => {
        if (desktopImages.length - 1 > currentImage) {
            setCurrentImage(prev => prev + 1);
        }
    };

    const prevImage = () => {
        if (currentImage !== 0) {
            setCurrentImage(prev => prev - 1);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (desktopImages.length - 1 > currentImage) {
                nextImage();
            } else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [currentImage, desktopImages]);

  

 

  
    if (!isLoading && desktopImages.length === 0 && mobileImages.length === 0) {
        return null;
    }

    return (
       <>
       
       <div className='relative w-full overflow-hidden font-montserrat'>
            <div className='h-80 md:h-96 w-full bg-slate-200'> {/* Increased height */}
                {/* Desktop controls */}
                <div className='absolute z-10 h-full w-full md:flex items-center hidden'>
                    <div className='flex justify-between w-full text-2xl'>
                        <button onClick={prevImage} className='bg-white shadow-md rounded-full p-1'><FaAngleLeft /></button>
                        <button onClick={nextImage} className='bg-white shadow-md rounded-full p-1'><FaAngleRight /></button>
                    </div>
                </div>

                {/* Desktop and tablet version */}
                {desktopImages.length > 0 && (
                    <div className='hidden md:flex h-full w-full'>
                        {desktopImages.map((imageURL, index) => (
                            <div
                                className='w-full h-full flex-shrink-0 transition-transform duration-500'
                                key={`desktop-${index}`}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                <img src={`https://builder.biztoindia.in/${imageURL.url}`}
                                    title={`Desktop Banner ${index}`}
                                    alt={`Desktop Banner ${index}`}
                                    className='w-full h-full object-cover' />
                            </div>
                        ))}
                    </div>
                )}

                {/* Mobile version */}
                {mobileImages.length > 0 && (
                    <div className='flex h-full w-full overflow-hidden md:hidden'>
                        {mobileImages.map((imageURL, index) => (
                            <div
                                className='w-full h-full flex-shrink-0 transition-transform duration-500'
                                key={`mobile-${index}`}
                                style={{ transform: `translateX(-${currentImage * 100}%)` }}
                            >
                                <img src={`https://builder.biztoindia.in/${imageURL.url}`} 
                                    alt={`Mobile Banner ${index}`}
                                    title={`Mobile Banner ${index}`}
                                    className='w-full h-full object-cover' />
                            </div>
                        ))}
                    </div>
                )}
            </div>


         


       


        </div>
       
       </>
       
    );
};

export default BannerProduct;
